import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import {PostHeader} from "../components/posts/postHeader"

import './blogTemplate.scss'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout condensedHeader={true}>
      <SEO title={`Agnoris - ${frontmatter.title}`} />
      <div className="agnoris-blog-post-container">
        <div className="agnoris-blog-post">
          <PostHeader post={markdownRemark} />
          <h1>{frontmatter.title}</h1>
          <div
            className="agnoris-blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
        author {
          id
          bio
          name
          avatar {
            children {
              ... on ImageSharp {
                fixed(width: 30, height: 30, quality: 75, grayscale: false) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }            
        }
      }
    }
  }
`